<template>
    <div>
        <!-- eslint-disable-next-line vue/no-unused-vars -->
        <div v-for="(filter, key, filterIndex) in availableFilters" :key="key">
            <h3>{{ filter.name }}</h3>
            <div v-for="(value, valuesKey, valuesIndex) in filter.options" :key="valuesKey" class="bottom-5">
                <input class="color" type="checkbox" :id="value + valuesIndex" :value="value" v-model="filter.selected" />
                <label :for="value + valuesIndex">{{ value }}</label>
            </div>
        </div>
        <button type="button" @click="applyFilter" class="apply-button">Apply</button>
    </div>
</template>

<script>
    /*
  Filters variable example: 
  Pass this this filter variable into FilterList as prop
    filters: {
        status: { name: 'Status', options: ['Active', 'Inactive'], selected: [] },
        treatment_staff: {
              name: 'Treatment Staff',
              options: ['Sigmund Freud', 'Ivan Pavlov'],
              selected: []
        },
        services_assigned: {
              name: 'Services Assigned',
              options: ['Family Counseling', 'Group Treatment'],
              selected: []
        }
    }
*/
    export default {
        props: {
            filters: {
                type: Object
            },
            selectedFilters: {
                type: Object
            }
        },
        data() {
            return {
                availableFilters: this.filters
            };
        },
        methods: {
            applyFilter() {
                let filters = {};
                for (let key in this.availableFilters) {
                    let selectedValues = this.availableFilters[key].selected;
                    if (selectedValues.length > 0) {
                        filters[key] = selectedValues;
                    }
                }
                this.$emit('getFilters', 'filter', filters);
            }
        },
        created() {
            for (let key in this.selectedFilters) {
                let filterOptionsSelected = this.selectedFilters[key];
                this.availableFilters[key].selected = filterOptionsSelected;
            }
        },
        mounted() {
            setTimeout(() => {
                let specifiedElement = document.getElementById('filter-menu');
                document.addEventListener('click', (event) => {
                    let isClickInside = specifiedElement.contains(event.target);
                    if (!isClickInside) {
                        this.$emit('closeFilter', false);
                    }
                });
            }, 500);
        }
    };
</script>

<style></style>
